@tailwind base;
/* 
  Fix for html2canvas text shift
  https://github.com/niklasvh/html2canvas/issues/2775
*/
@layer base {
  img {
    @apply inline-block;
  }
}
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Rubik;
    font-weight: 300;
    src: url("../public/fonts/Rubik-Light.ttf");
  }

  @font-face {
    font-family: Rubik;
    font-weight: 400;
    src: url("../public/fonts/Rubik-Regular.ttf");
  }

  @font-face {
    font-family: Rubik;
    font-weight: 500;
    src: url("../public/fonts/Rubik-Medium.ttf");
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 5em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
}
